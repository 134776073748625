import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Table from 'components/ui/Table/ui/Table';
import ButtonUI from 'components/ui/ButtonUI/ButtonUI';
import { TextUI } from 'components/ui/TextUI/ui/TextUI';
import { useGetBidsByUserIdQuery } from 'services/api';
import { useSelector } from 'react-redux';
import ArrowRight from 'assets/icons/ArrowRight';
import { format } from 'date-fns';
import RedSign from '../../../assets/icons/red-sign.svg';
import { useMediaQuery } from 'shared/hooks/UseMediaQuery';
import { useRetry } from 'shared/hooks/useRetry';

import styles from './PendingApplicationsList.module.scss';

const PendingApplicationsList = () => {
    const navigate = useNavigate();
    const userId = useSelector((state: any) => state.login?.user?._id); // TODO: или использовать userId из сквозной нумерации (1, 2, 3, ...) ???
    const isMobile = useMediaQuery(768)

    // Получение заявок по userId
    const { data: applications = [], isLoading, error = '', refetch } = useGetBidsByUserIdQuery(userId);
    // console.log("🚀 ~ PendingApplicationsList ~ applications:", applications)

    // Автоматический повторный запрос при ошибке
    useRetry({
        isError: !!error,
        retry: refetch,
        maxAttempts: 1,
        delay: 1000
    });

    // Сортируем заявки по дате создания (от новых к старым)
    const sortedApplications = [...applications].sort((a, b) => {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });

    // Определение колонок таблицы
    const columns = useMemo(
        () => [
            {
                accessorKey: 'offer',
                header: 'Название оффера',
                cell: ({ row }: any) => (
                    <div className={styles.bankProduct}>
                        <div>{row.original.offer.name}</div>
                        <div className={styles.subTitle}>{row.original.offer.category}</div>
                    </div>
                ),
            },
            {
                accessorKey: 'clientName',
                header: 'ФИО Клиента',
                cell: ({ row }: any) => {
                    const { surname, name, patronymic } = row.original.personalData || {};
                    const fullName = [surname, name, patronymic].filter(Boolean).join(' ');
                    return (
                        <div className={styles.clientNameCell}>
                            <span>{fullName}</span>
                        </div>
                    );
                },
            },
            {
                accessorKey: 'createdAt',
                header: 'Дата начала',
                cell: ({ row }: any) => (
                    <div>{new Date(row.original.createdAt).toLocaleDateString('ru-RU')}</div>
                ),
            },
            {
                accessorKey: 'hoursLeft',
                header: 'Часов осталось',
                cell: ({ row }: any) => {
                    const createdAt = new Date(row.original.createdAt);
                    const expirationTime = new Date(createdAt.getTime() + 24 * 60 * 60 * 1000); // Добавляем 24 часа
                    const currentTime = new Date();
                    const diffInHours = Math.max(0, Math.floor((expirationTime.getTime() - currentTime.getTime()) / (1000 * 60 * 60))); // Рассчитываем разницу в часах

                    return (
                        <span className={diffInHours < 24 ? styles.lowHours : ''}>
                            {diffInHours}
                        </span>
                    );
                },
            },
            {
                id: 'continue',
                header: '',
                cell: ({ row }: any) => {
                    // const createdAt = new Date(row.original.createdAt);
                    // const expirationTime = new Date(createdAt.getTime() + 24 * 60 * 60 * 1000); // Добавляем 24 часа
                    // const currentTime = new Date();
                    // const diffInHours = Math.max(0, Math.floor((expirationTime.getTime() - currentTime.getTime()) / (1000 * 60 * 60))); // Рассчитываем разницу в часах

                    const isCanceled = row.original.status === 'canceled';
                    // console.log("🚀 ~ file: PendingApplicationsList.tsx:77 ~ row.original.status:", row.original.status)
                    // console.log("🚀 ~ file: PendingApplicationsList.tsx:77 ~ isCanceled:", isCanceled)

                    return (
                        <ButtonUI
                            label={isCanceled ? "Заявка отменена" : "Продолжить"}
                            variant="main2"
                            height="42px"
                            disabled={isCanceled}
                            onClick={() => {
                                navigate(`/request-card?step=${row.original.currentStep}&requestId=${row.original._id}`, {
                                    state: { fromPendingList: true }
                                });
                            }}
                            iconRight={!isCanceled && <ArrowRight scale={0.7} />}
                        />
                    );
                },
            },
        ],
        [navigate]
    );

    if (isLoading) return <div>Загрузка...</div>;
    if (error) return (
        <div className={styles.errorContainer}>
            <TextUI variant="h2" className={styles.errorTitle}>
                Произошла ошибка при загрузке заявок
            </TextUI>
            <TextUI variant="bodyM" className={styles.errorMessage}>
                Пожалуйста, попробуйте позже или обновите страницу
            </TextUI>
            <ButtonUI
                label="Повторить попытку"
                variant="main2"
                onClick={() => refetch()}
            />
        </div>
    );

    return (
        <div className={styles.pendingApplicationsList}>
            {isMobile && <div className={styles.explainedText}>
                <img src={RedSign} alt="red sign" />
                <TextUI variant="bodyM">
                    Заявки остаются незавершёнными в течение суток, далее переходят в статус "Отклонено".
                </TextUI>
            </div>}
            <TextUI variant="h1" className={styles.title}>
                Незавершённые заявки
            </TextUI>
            <Table
                columns={columns}
                data={sortedApplications}
            />
            {!isMobile &&
                <div className={styles.explainedText}>
                    <img src={RedSign} alt="red sign" />
                    <TextUI variant="bodyM">
                        Заявки остаются незавершёнными в течение суток, далее переходят в статус "Отклонено".
                    </TextUI>
                </div>
            }
        </div>
    );
};

export default PendingApplicationsList;
