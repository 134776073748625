import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { UserData } from 'shared/models';
import { PersonalData } from 'shared/types/personalData';
import { logoutUser } from '../features/login/loginSlice';

interface ConfirmationCodeData {
    confirmationCode: string;
}
//try to fix merge request (not working)
interface UploadFileResponse {
    status: string;
    message: string;
    uploadedFiles: string[];
    documentFiles: string[];
    clientFiles: string[];
    pdnFiles: string[];
}

interface UploadFileRequest {
    files: { name: string; base64: string }[];
    id: string;
}

interface UploadFormDataResponse {
    status: string;
    message: string;
    documentFiles: string[];
    clientFiles: string[];
    pdnFiles: string[];
}

interface UploadFormDataRequest {
    formData: FormData;
    id: string;
}

const baseUrl = process.env.REACT_APP_API_URL

const baseQuery = fetchBaseQuery({
    baseUrl: baseUrl + '/api',
    prepareHeaders: (headers) => {
        const token = localStorage.getItem('token');
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    },
});

const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
    const result = await baseQuery(args, api, extraOptions);

    if (result?.error?.status === 401) {
        // Используем существующий logoutUser из store
        api.dispatch(logoutUser());
        window.location.href = '/login';
    }

    return result;
};

export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        // Аутентификация
        login: builder.mutation({
            query: (credentials) => ({
                url: '/user/login',
                method: 'POST',
                body: credentials,
            }),
        }),
        refresh: builder.query({
            query: () => '/user/refresh',
        }),
        logout: builder.mutation({
            query: () => ({
                url: '/user/logout',
                method: 'POST',
            }),
        }),
        // Восстановление пароля
        recoveryPassword: builder.mutation({
            query: (email) => ({
                url: '/user/recovery-password',
                method: 'POST',
                body: { email },
            }),
        }),
        // Получение пользователя по email
        getUserByEmail: builder.query({
            query: (email) => `/user/by-email/${email}`,
        }),
        fetchOffers: builder.query({
            query: () => '/user/offers',
        }),
        registerUser: builder.mutation({
            query: (userData: UserData) => ({
                url: '/user/registration',
                method: 'POST',
                body: userData,
            }),
        }),
        loginUser: builder.mutation({
            query: (userData: any) => ({
                url: '/user/login',
                method: 'POST',
                body: userData,
            }),
        }),
        approveRegistrationCode: builder.mutation({
            query: (data: ConfirmationCodeData) => ({
                url: '/user/verify-code',
                method: 'POST',
                body: data,
            }),
        }),
        fetchPartner: builder.query({
            query: (pid: string) => `/user/partners?affiseId=${pid}`,
        }),
        repeatConfirmCode: builder.mutation({
            query: (email: string) => ({
                url: '/user/repeat-confirm-code',
                method: 'POST',
                body: { email },
            }),
        }),
        checkAuth: builder.query({
            query: () => '/user/refresh',
        }),
        updateUser: builder.mutation({
            query: (userData: Partial<UserData>) => ({
                url: `/user/update-user`, // Это ваш эндпоинт для обновления пользователя
                method: 'PUT',
                body: userData,
            }),
        }),
        updatePassword: builder.mutation({
            query: (data: { email: string; currentPassword?: string; newPassword: string; recoverPasswordLink?: string, timestamp?: string }) => ({
                url: `/user/updatePassword`,
                method: 'POST',
                body: data,
            }),
        }),
        // fetchDailyStatistic: builder.query({
        //     query: () => '/user/daily-statistic',
        // }),
        // getOfferStatistics: builder.query({
        //     query: () => '/user/offer-statistic',
        // }),
        uploadFiles: builder.mutation<UploadFileResponse, UploadFileRequest>({
            query: ({ files, id }) => ({
                url: `/partners/vtb/files/${id}/upload`,   // /partners/vtb/files//files/:id/upload
                method: 'POST',
                body: files,
            }),
        }),
        uploadFilesSandbox: builder.mutation<UploadFormDataResponse, UploadFormDataRequest>({
            query: ({ formData, id }) => ({
                url: `/bids/${id}/upload`,
                method: 'POST',
                body: formData,
            }),
        }),
        updateSandboxStatus: builder.mutation<{ success: boolean; sandbox: boolean }, { id: string; sandbox: boolean }>({
            query: ({ id, sandbox }) => ({
                url: `/bids/${id}/sandbox`,
                method: 'PATCH',
                body: { sandbox },
            }),
        }),
        getApplications: builder.query({
            query: () => '/bids',
            transformResponse: (response: any[]) => {
                return response.map(bid => ({
                    id: bid._id,
                    date: bid.createdAt,
                    offer: bid.offer,
                    status: bid.status,
                    paymentStatus: bid.paymentStatus || 'pending',
                    client: bid.personalData?.name || '',
                    bidNumber: bid.bidNumber
                }));
            }
        }),
        // Новый эндпоинт для создания новой заявки
        createCardRequest: builder.mutation({
            query: (data) => ({
                url: `/bids/create`,
                method: 'POST',
                body: data,
            }),
        }),
        // Новый эндпоинт для создания или обновления данных заявки по шагам
        updateRequestStep: builder.mutation({
            query: ({ id, step, data }) => ({
                url: `/bids/${id}/step/${step}`,
                method: 'PUT',
                body: data,
            }),
        }),
        requestSmsCode: builder.mutation({
            query: (requestId: string) => ({
                url: `/bids/${requestId}/send-phone-code`,
                method: 'GET',
            }),
        }),
        verifySmsCode: builder.mutation({
            query: ({ requestId, code }) => ({
                url: `/bids/${requestId}/verify-phone-code`,
                method: 'POST',
                body: { code },
            }),
        }),
        getCardRequestById: builder.query({
            query: (id) => `/bids/${id}`,
        }),
        // Новый эндпоинт для обновления статуса заявки
        updateRequestStatus: builder.mutation({
            query: ({ id, status }) => ({
                url: `/bids/${id}/status`,
                method: 'PATCH',
                body: { status },
            }),
        }),
        // getPhotoData: builder.query({
        //     query: (id: string) => `/bids/${id}/photos`,
        // }),
        // Удаление фото документов
        // deleteDocumentPhoto: builder.mutation({
        //     query: ({ id, photoPath }) => ({
        //         url: `/bids/${id}/document`,
        //         method: 'DELETE',
        //         body: { photoPath }, // передаем путь к фото в теле запроса
        //     }),
        // }),
        // Удаление фото клиента
        // deleteClientPhoto: builder.mutation({
        //     query: ({ id, photoPath }) => ({
        //         url: `/bids/${id}/client`,
        //         method: 'DELETE',
        //         body: { photoPath }, // передаем путь к фото в теле запроса
        //     }),
        // }),
        // Удаление PDN файла
        // deletePdnFile: builder.mutation({
        //     query: ({ id, photoPath }) => ({
        //         url: `/bids/${id}/pdn`,
        //         method: 'DELETE',
        //         body: { photoPath }
        //     }),
        // }),
        getBidsByUserId: builder.query({
            query: (userId) => `/bids/user/${userId}`,
        }),
        getAllBids: builder.query({
            query: () => '/bids',
        }),
        // Инициализация кредитной карты
        initCreditCard: builder.mutation({
            query: ({ requestId }) => ({
                url: `/partners/vtb/${requestId}/init`,
                method: 'POST',
                body: {}
            })
        }),
        checkCreditCard: builder.mutation({
            query: ({ id, ean }) => ({
                url: `/bids/${id}/credit-card/check-card`,
                method: 'POST',
                body: { ean }
            }),
        }),
        getBidStatus: builder.query({
            query: ({ id, type }) => `/bids/${id}/status/${type}`,
        }),
        issueCard: builder.mutation({
            query: (requestId: string) => ({
                url: `/partners/vtb/${requestId}/issue`,
                method: 'POST',
                body: {}
            })
        }),
        // Отмена кредитной карты
        cancelCreditCard: builder.mutation<any, { requestId: string }>({
            query: ({ requestId }) => ({
                url: `/partners/vtb/${requestId}/cancel`,
                method: 'POST',
            }),
        }),
        getPublicUrl: builder.query({
            query: (id: string) => `/bids/${id}/public-url`,
        }),
        getByHash: builder.query({
            query: (hash: string) => `/bids/pub/${hash}`,
        }),
        generateDocuments: builder.mutation({
            query: (requestId: string) => ({
                url: `/partners/vtb/${requestId}/generate-documents`,
                method: 'POST',
                body: {}
            })
        }),
        getDocumentGenerationStatus: builder.query({
            query: (requestId: string) => `/bids/${requestId}/status/generate_documents`,
        }),
        uploadPepConsentPhoto: builder.mutation({
            query: ({ requestId, objectName, formData }) => ({
                url: `/partners/vtb/files/upload/${requestId}/${objectName}`,
                method: 'POST',
                body: formData,
            }),
        }),
        uploadPepConsentPhotoSandbox: builder.mutation({
            query: ({ requestId, formData }) => ({
                url: `/bids/${requestId}/pep-consent`,
                method: 'POST',
                body: formData,
            }),
        }),
        deletePepConsentPhoto: builder.mutation({
            query: ({ id, photoPath }) => ({
                url: `/bids/${id}/pep-consent`,
                method: 'DELETE',
                body: { photoPath },
            }),
        }),
        // Сброс статуса операции
        resetOperationStatus: builder.mutation({
            query: ({ id, operationType }) => ({
                url: `/bids/${id}/reset-operation/${operationType.replace('_', '-')}`,
                method: 'POST'
            }),
        }),
        generatePep: builder.mutation({
            query: (requestId: string) => ({
                url: `/partners/vtb/${requestId}/generate-pep`,
                method: 'GET'
            }),
        }),
    }),
});

export const {
    useFetchOffersQuery,
    useRegisterUserMutation,
    useLoginUserMutation,
    useApproveRegistrationCodeMutation,
    useFetchPartnerQuery,
    useRepeatConfirmCodeMutation,
    useCheckAuthQuery,
    useVerifySmsCodeMutation,
    useUpdateUserMutation,
    useUpdatePasswordMutation,
    // useFetchDailyStatisticQuery,
    // useGetOfferStatisticsQuery,
    useUploadFilesMutation,
    useUploadFilesSandboxMutation,
    useGetApplicationsQuery,
    useCreateCardRequestMutation,
    useGetCardRequestByIdQuery,
    useUpdateRequestStepMutation,
    useUpdateRequestStatusMutation,
    useRequestSmsCodeMutation,
    // useDeleteDocumentPhotoMutation,
    // useDeleteClientPhotoMutation,
    // useDeletePdnFileMutation,
    useGetBidsByUserIdQuery,
    useUpdateSandboxStatusMutation,
    useGetAllBidsQuery,
    useInitCreditCardMutation,
    useCheckCreditCardMutation,
    useGetBidStatusQuery,
    useCancelCreditCardMutation,
    useGetPublicUrlQuery,
    useGetByHashQuery,
    useGenerateDocumentsMutation,
    useGetDocumentGenerationStatusQuery,
    useUploadPepConsentPhotoMutation,
    useUploadPepConsentPhotoSandboxMutation,
    useDeletePepConsentPhotoMutation,
    useResetOperationStatusMutation,
    useGeneratePepMutation,
    useIssueCardMutation,
    useLoginMutation,
    useRefreshQuery,
    useLogoutMutation,
    useRecoveryPasswordMutation,
    useGetUserByEmailQuery,
} = apiSlice;