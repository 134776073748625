import React, { useState, useEffect } from 'react';
import { PersonalDataForm } from 'components/Forms/PersonalDataForm';
import { useDispatch, useSelector } from 'react-redux';
import { savePersonalData } from 'features/request/requestSlice';
import InputCheckboxUI from 'components/ui/InputCheckboxUI/InputCheckboxUI';
import { Link } from 'react-router-dom';
import { useGetCardRequestByIdQuery, useUpdateRequestStepMutation } from 'services/api';
import { PersonalData } from 'shared/types/personalData';

import styles from './Step1.module.scss';

interface Step1Props {
    setIsFormValid: (isValid: boolean) => void;
    onFormSubmit: (data: PersonalData) => void;
    requestId: string | null;
    defaultValues: Partial<PersonalData>;
}

const Step1: React.FC<Step1Props> = ({ setIsFormValid, onFormSubmit, requestId, defaultValues }) => {
    const dispatch = useDispatch();
    // const personalData = useSelector((state: any) => state.request.personalData); // Получаем персональные данные из Redux
    const [updateRequestStep] = useUpdateRequestStepMutation();
    const { refetch } = useGetCardRequestByIdQuery(requestId!, { skip: !requestId });
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(true);
    const [isFormDataValid, setIsFormDataValid] = useState(false);
    // console.log("🚀 ~ file: Step1.tsx:26 ~ isFormDataValid:", isFormDataValid)

    // Обновляем общую валидность формы при изменении чекбокса или данных формы
    useEffect(() => {
        setIsFormValid(isFormDataValid && isCheckboxChecked);
    }, [isFormDataValid, isCheckboxChecked, setIsFormValid]);

    const handleFormSubmit = async (data: PersonalData) => {
        dispatch(savePersonalData(data));
        onFormSubmit(data);
    };

    return (
        <div className={styles.step1}>
            <PersonalDataForm
                setIsFormValid={setIsFormDataValid}
                onFormSubmit={handleFormSubmit}
                defaultValues={defaultValues}
                requestId={requestId}
            />
            {/* <div className={styles.checkboxWrapper}>
                <InputCheckboxUI
                    className="register-checkbox"
                    id="checkbox"
                    onChange={(e: any) => setIsCheckboxChecked(e.target.checked)}
                    value={isCheckboxChecked}
                />
                <div>
                    Я соглашаюсь с {' '}
                    <Link to="/privacy-policy" target="_blank">
                        условиями
                    </Link>{' '}
                    обработки и использования персональных данных
                </div>
            </div> */}
        </div>
    );
};

export default Step1;
