import React, { useEffect } from 'react';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { CustomInput } from 'components/CustomInput/CustomInput';
import { InputTypeEnum } from 'shared/models';
import { CardWrapper } from 'components/CardWrapper/CardWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { saveAdditionalData } from 'features/request/requestSlice';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import styles from './Step3.module.scss';
import { Counter } from 'components/Counter';
import { useGetCardRequestByIdQuery } from 'services/api';
import { CustomSelect } from 'components/ui/CustomSelect';

interface Step3Props {
  requestId: string | null;
  setIsFormValid: (isValid: boolean) => void;
  onFormSubmit: (data: any) => void;
}

const validationSchema = yup.object().shape({
  maritalStatus: yup.string().required('Поле обязательно для заполнения'),
  childrenUnder18: yup
    .number()
    .required('Поле обязательно для заполнения')
    .min(0, 'Минимальное значение 0')
    .max(10, 'Максимальное значение 10'),
  educationType: yup.string().required('Поле обязательно для заполнения'),
  incomeType: yup.string().required('Поле обязательно для заполнения'),
  monthlyIncome: yup
    .number()
    .required('Поле обязательно для заполнения')
    .typeError('Должно быть числом')
    .min(10000, 'Доход должен быть не менее 10000'),
  organizationName: yup
    .string()
    .required('Поле обязательно для заполнения')
    .min(2, 'Слишком короткое значение'),
  organizationINN: yup
    .string()
    .required('Поле обязательно для заполнения')
    .matches(/^\d{10,12}$/, 'ИНН должен содержать от 10 до 12 цифр')
});

const Step3: React.FC<Step3Props> = ({ requestId, setIsFormValid, onFormSubmit }) => {
  const dispatch = useDispatch();

  const { data: requestData } = useGetCardRequestByIdQuery(requestId, { skip: !requestId });

  const additionalData = useSelector(
    (state: any) => state.request.data.additionalData || {}
  );

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      maritalStatus: additionalData.maritalStatus || '',
      childrenUnder18: additionalData.childrenUnder18 || 0,
      educationType: additionalData.educationType || '',
      incomeType: additionalData.incomeType || '',
      monthlyIncome: additionalData.monthlyIncome || '',
      organizationName: additionalData.organizationName || '',
      organizationINN: additionalData.organizationINN || '',
    },
  });

  const {
    formState: { isValid },
    handleSubmit,
    watch,
    control,
    reset,
  } = methods;

  useEffect(() => {
    if (requestData && requestData.additionalData) {
      reset(requestData.additionalData);
      dispatch(saveAdditionalData(requestData.additionalData));
    }
  }, [requestData, reset, dispatch]);

  useEffect(() => {
    setIsFormValid(isValid);
  }, [isValid, setIsFormValid]);

  useEffect(() => {
    const subscription = watch((data) => {
      dispatch(saveAdditionalData(data));
    });

    return () => subscription.unsubscribe();
  }, [watch, dispatch]);

  const onSubmit = (data: any) => {
    console.log('Форма отправлена с данными:', data);
    dispatch(saveAdditionalData(data));
    onFormSubmit(data);
  };

  const maritalStatusOptions = [
    { id: 'Married', title: 'Замужем/Женат' },
    { id: 'Single', title: 'Не замужем/Холост' },
  ];

  const educationTypeOptions = [
    { id: 'School', title: 'Среднее образование' },
    { id: 'SpecializedSchool', title: 'Среднее профессиональное образование' },
    { id: 'IncompleteHigher', title: 'Незаконченное высшее образование' },
    { id: 'Higher', title: 'Высшее образование' },
  ];

  const incomeTypeOptions = [
    { id: 'Basic', title: 'Доход по основному месту работы' },
    { id: 'SelfEmployment', title: 'Доход самозанятого' },
    { id: 'Pension', title: 'Пенсия (неработающий пенсионер)' },
  ];

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.step3}>
        <CardWrapper title="Семья">
          <Controller
            name="maritalStatus"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomSelect
                selectedValue={value}
                setSelectedValue={onChange}
                options={maritalStatusOptions}
                // label="Семейное положение"
                placeholder="Семейное положение"
              />
            )}
          />
          <Controller
            name="childrenUnder18"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Counter
                initialValue={value}
                min={0}
                max={10}
                onChange={(newValue) => onChange(newValue)}
              />
            )}
          />
        </CardWrapper>
        <div className={styles.columnWrapper}>
          <CardWrapper title="Образование">
            <Controller
              name="educationType"
              control={control}
              render={({ field: { onChange, value } }) => (
                <CustomSelect
                  selectedValue={value}
                  setSelectedValue={onChange}
                  options={educationTypeOptions}
                  label="Тип образования"
                  placeholder="Выберите тип образования"
                />
              )}
            />
          </CardWrapper>
          <CardWrapper title="Доход">
            <Controller
              name="incomeType"
              control={control}
              render={({ field: { onChange, value } }) => (
                <CustomSelect
                  selectedValue={value}
                  setSelectedValue={onChange}
                  options={incomeTypeOptions}
                  label="Тип дохода"
                  placeholder="Выберите тип дохода"
                />
              )}
            />
            <CustomInput
              name="monthlyIncome"
              type={InputTypeEnum.Number}
              placeholder="Ежемесячный доход"
              description="Укажите среднюю з/п за последние 3 месяца с учетом вычета налога"
              required
              hideIcon
            />
            <CustomInput
              name="organizationName"
              type={InputTypeEnum.Text}
              placeholder="Название организации"
              required
              hideIcon
            />
            <CustomInput
              name="organizationINN"
              type={InputTypeEnum.Text}
              placeholder="ИНН"
              required
              hideIcon
            />
          </CardWrapper>
        </div>
      </form>
    </FormProvider>
  );
};

export default Step3;
