import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFetchOffersQuery } from 'services/api';
import { resetRequest, setSelectedBank, setSelectedProduct } from 'features/request/requestSlice';
import ButtonUI from 'components/ui/ButtonUI/ButtonUI';

import styles from './OfferDetails.module.scss';

const OfferDetails = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { id } = useParams();
    const { data: offers = [], isLoading, error } = useFetchOffersQuery({ page: 1 });

    if (isLoading) return <div>Загрузка...</div>;
    if (error) return <div>Ошибка загрузки данных</div>;

    const offer = offers.find((o: any) => o.id === Number(id));

    if (!offer) return <div>Оффер не найден</div>;

    const handleSendRequest = () => {
        // Сбрасываем предыдущее состояние заявки
        dispatch(resetRequest());
        // Устанавливаем новые значения
        dispatch(setSelectedBank(offer.title));
        dispatch(setSelectedProduct(offer.description));
        // Переходим на страницу заявки
        navigate('/request-card');
    };

    return (
        <div className={styles.container}>
            <div className={styles.titleWrapper}>
                <div className={styles.offerCellImage}>
                    <img className={styles.logo} src={offer.logo} alt={offer.title} />
                </div>
                <div>
                    <h1 className={styles.title}>{offer.title}</h1>
                    <p className={styles.description}>{offer.description}</p>
                </div>
            </div>
            <div className={styles.details}>
                <p>Адрес: {offer.address}</p>
                <p>Тел.: {offer.phone}</p>
                <p>S.W.I.F.T.: {offer.SWIFT}</p>
                <p>email: <a href={`mailto:${offer.email}`}>{offer.email}</a></p>
                <div className={styles.detailsRow}>
                    <div><strong>Полное фирменное наименование:</strong></div>
                    <p> {offer.full_firm_name}</p>
                </div>
                <div className={styles.detailsRow}>
                    <div><strong>Место нахождения:</strong></div>
                    <p> {offer.address}</p>
                </div>
                <div className={styles.detailsRow}>
                    <div><strong>Почтовый адрес:</strong></div>
                    <p> {offer.address}</p>
                </div>
                <ButtonUI
                    label="ОТПРАВИТЬ ЗАЯВКУ"
                    variant="secondary"
                    height="42px"
                    className={styles.button}
                    onClick={handleSendRequest}
                />
            </div>
        </div>
    );
};

export default OfferDetails;
