import { useEffect } from 'react';

interface UseRetryProps {
    isError: boolean;
    retry: () => void;
    maxAttempts?: number;
    delay?: number;
}

export const useRetry = ({
    isError,
    retry,
    maxAttempts = 1,
    delay = 1000
}: UseRetryProps) => {
    useEffect(() => {
        let attempts = 0;
        let timeoutId: NodeJS.Timeout;

        const attemptRetry = () => {
            if (isError && attempts < maxAttempts) {
                timeoutId = setTimeout(() => {
                    attempts++;
                    retry();
                }, delay);
            }
        };

        attemptRetry();

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [isError, retry, maxAttempts, delay]);
};
