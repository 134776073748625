export enum InputTypeEnum {
  Text = 'text',
  Email = 'email',
  Password = 'password',
  Tel = 'tel',
  Messenger = 'messenger',
  Date = 'date',
  FIO = 'fio',
  Number = 'number'
}

export interface UserData {
  _id?: string;
  name?: string;
  email: string;
  messenger?: string;
  phone?: string;
  password?: string;
  partnerId?: string;
  confirmationCode?: string;
  status?: 'active' | 'inactive';
  authorized?: boolean;
}

export interface LoginState {
  user: UserData | null;
  isLoading: boolean;
  error: string | null;
  isAuthChecked: boolean;
  isAuth: boolean;
}

export interface Offer {
  id: number;
  name: string;
  full_categories?: any;
  title?: string;
}

export interface StatisticItem {
  date: string;
  all: number;
  approved: number;
  inProgress: number;
  rejected: number;
  offers: Offer[];
}
