// src/store/slices/requestSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PersonalData } from 'shared/types/personalData';
import { RootState } from '../../app/store';

interface RequestState {
    currentStep: number;
    status: 'draft' | 'send' | 'pending' | 'approved' | 'rejected';
    requestId: string | null;
    data: {
        offer: {
            id: number;
            name: string;
            category: string;
        } | null;
        selectedBank: string;
        selectedProduct: string;
        personalData: Partial<PersonalData>;
        additionalData: any;
        requestedAmount: number;
        documentPhotos: string[]; // массив путей для фото документов
        clientPhoto: string | null;
        partnerResponseMessage?: string;
        partnerResponseCode?: string;
    };
}

const initialState: RequestState = {
    currentStep: 0,
    status: 'draft',
    requestId: null,
    data: {
        offer: null,
        selectedBank: '',
        selectedProduct: '',
        personalData: {},
        additionalData: {},
        requestedAmount: 90000,
        documentPhotos: [],
        clientPhoto: null,
        partnerResponseMessage: '',
        partnerResponseCode: '',
    },
};

export const requestSlice = createSlice({
    name: 'request',
    initialState,
    reducers: {
        resetRequest: (state) => {
            return initialState;
        },
        setCurrentStep: (state, action: PayloadAction<number>) => {
            state.currentStep = action.payload;
        },
        setStatus: (state, action: PayloadAction<RequestState['status']>) => {
            state.status = action.payload;
        },
        setRequestId: (state, action: PayloadAction<string>) => {
            state.requestId = action.payload;
        },
        setOffer: (state, action: PayloadAction<RequestState['data']['offer']>) => {
            state.data.offer = action.payload;
        },
        setSelectedBank: (state, action: PayloadAction<string>) => {
            state.data.selectedBank = action.payload;
        },
        setSelectedProduct: (state, action: PayloadAction<string>) => {
            state.data.selectedProduct = action.payload;
        },
        savePersonalData: (state, action: PayloadAction<Partial<PersonalData>>) => {
            state.data.personalData = action.payload;
        },
        saveAdditionalData: (state, action: PayloadAction<any>) => {
            state.data.additionalData = action.payload;
        },
        setRequestedAmount: (state, action: PayloadAction<number>) => {
            state.data.requestedAmount = action.payload; // Сохраняем сумму кредита
        },
        setDocumentPhotos: (state, action: PayloadAction<string[]>) => {
            state.data.documentPhotos = action.payload;
        },
        addDocumentPhoto: (state, action: PayloadAction<string>) => {
            state.data.documentPhotos.push(action.payload);
        },
        removeDocumentPhoto: (state, action: PayloadAction<string>) => {
            state.data.documentPhotos = state.data.documentPhotos.filter(photo => photo !== action.payload);
        },
        setClientPhoto: (state, action: PayloadAction<string | null>) => {
            state.data.clientPhoto = action.payload;
        },
        setPartnerResponseMessage: (state, action: PayloadAction<string>) => {
            state.data.partnerResponseMessage = action.payload;
        },
        setPartnerResponseCode: (state, action: PayloadAction<string>) => {
            state.data.partnerResponseCode = action.payload;
        },
        clearPartnerResponse: (state) => {
            state.data.partnerResponseMessage = '';
            state.data.partnerResponseCode = '';
        },
    },
});

export const {
    resetRequest,
    setCurrentStep,
    setStatus,
    setRequestId,
    setOffer,
    setSelectedBank,
    setSelectedProduct,
    savePersonalData,
    saveAdditionalData,
    setRequestedAmount,
    setDocumentPhotos,
    addDocumentPhoto,
    removeDocumentPhoto,
    setClientPhoto,
    setPartnerResponseMessage,
    setPartnerResponseCode,
    clearPartnerResponse,
} = requestSlice.actions;

export const selectCurrentStep = (state: RootState) => state.request.currentStep;
export const selectStatus = (state: RootState) => state.request.status;
export const selectRequestId = (state: RootState) => state.request.requestId;
export const selectOffer = (state: RootState) => state.request.data.offer;
export const selectSelectedBank = (state: RootState) => state.request.data.selectedBank;
export const selectSelectedProduct = (state: RootState) => state.request.data.selectedProduct;
export const selectPersonalData = (state: RootState) => state.request.data.personalData;
export const selectAdditionalData = (state: RootState) => state.request.data.additionalData;
export const selectRequestedAmount = (state: RootState) => state.request.data.requestedAmount;
export const selectDocumentPhotos = (state: RootState) => state.request.data.documentPhotos;
export const selectClientPhoto = (state: RootState) => state.request.data.clientPhoto;
export const selectPartnerResponseMessage = (state: RootState) => state.request.data.partnerResponseMessage;
export const selectPartnerResponseCode = (state: RootState) => state.request.data.partnerResponseCode;

export default requestSlice.reducer;