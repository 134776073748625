import { useEffect, useState } from 'react';

export function useSliderDebounce<T>(value: T, delay: number): T {
    const [debouncedValue, setDebouncedValue] = useState<T>(value);

    useEffect(() => {
        // Если значение null или undefined, не устанавливаем таймер
        if (value === null || value === undefined) return;

        const timer = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        // Очищаем таймер при размонтировании компонента
        // или когда value или delay изменяются
        return () => {
            clearTimeout(timer);
        };
    }, [value, delay]);

    return debouncedValue;
}
