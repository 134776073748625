import React from 'react';
// import { Icon } from '@iconify/react';
import FileIcon from 'assets/icons/file_icon.svg';

import styles from '../PhotoForm.module.scss';
import ButtonUI from 'components/ui/ButtonUI/ButtonUI';

interface FilePreviewProps {
    files: (File | string)[];
    onDelete: (index: number) => void;
    type: 'document' | 'client' | 'pdn';
}

export const FilePreview: React.FC<FilePreviewProps> = ({ files, onDelete, type }) => {
    const getFileName = (file: File | string) => {
        if (typeof file === 'string') {
            return file.split('/').pop() || file;
        }
        return file.name;
    };

    return (
        <div className={styles.previewContainer}>
            {files.map((file, index) => (
                <div key={index} className={styles.previewItem}>
                    <div className={styles.fileName}>
                        <img
                            src={FileIcon}
                            alt={getFileName(file)}
                            className={styles.previewImage}
                        />
                        {getFileName(file)}
                    </div>
                    <ButtonUI
                        label="Удалить"
                        variant="link"
                        onClick={() => onDelete(index)}
                        height="28px"
                    />
                </div>
            ))}
        </div>
    );
};
