import React, { useRef, useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { CardWrapper } from 'components/CardWrapper/CardWrapper';
import { useUploadFilesMutation, useUploadFilesSandboxMutation } from 'services/api';
import { FileDropzone } from './components/FileDropzone';
import { FilePreview } from './components/FilePreview';
import { usePhotoUpload } from './hooks/usePhotoUpload';
import { formatFileName, createFileWithNewName, FileType } from './utils/fileUtils';
import { PhotoFormProps } from './types';
import ButtonUI from 'components/ui/ButtonUI/ButtonUI';
import { Icon } from '@iconify/react';
import ModalWithChildren from 'components/ui/ModalWithChildren/ui/ModalWithChildren';
import YellowClockIcon from 'assets/icons/clock_yellow.svg';

import styles from './PhotoForm.module.scss';

// interface UploadedFile {
//     name: string;
//     base64: string;
// }

// interface ServerData {
//     documentFiles?: string[];
//     clientFiles?: string[];
//     pdnPhoto?: string[];
//     bucketUploadedStatuses?: {
//         DUL?: number;
//         DUL1?: number;
//         DUL2?: number;
//         PHOTO?: number;
//         PDN?: number;
//     };
// }

export const PhotoForm: React.FC<PhotoFormProps> = ({
    setIsFormValid,
    onFormSubmit,
    serverData,
    requestId,
    refetch,
    sandbox = false
}) => {
    const methods = useForm();
    const { handleSubmit, formState: { isValid } } = methods;

    const [documentFileUrls, setDocumentFileUrls] = useState<string[]>([]);
    const [clientFileUrls, setClientFileUrls] = useState<string[]>([]);
    const [pdnFileUrls, setPdnFileUrls] = useState<string[]>([]);
    const [documentFiles, setDocumentFiles] = useState<File[]>([]);
    const [clientFiles, setClientFiles] = useState<File[]>([]);
    const [pdnFiles, setPdnFiles] = useState<File[]>([]);
    const [isSingleDocumentFile, setIsSingleDocumentFile] = useState(false);
    const [isFilesReady, setIsFilesReady] = useState(false);
    const [isDocumentUploadSuccess, setDocumentUploadSuccess] = useState(false);
    const [isClientUploadSuccess, setClientUploadSuccess] = useState(false);
    const [isPdnUploadSuccess, setPdnUploadSuccess] = useState(false);

    const [uploadFiles, { isLoading }] = useUploadFilesMutation();
    const [uploadFilesSandbox, { isLoading: isLoadingSandbox }] = useUploadFilesSandboxMutation();

    const [uploadError, setUploadError] = useState<string | null>(null);
    const [isModalErrorOpen, setIsModalErrorOpen] = useState(false);

    const handleModalErrorClose = () => {
        setIsModalErrorOpen(false);
        setUploadError(null);
    };

    useEffect(() => {
        if (serverData) {
            const documentFiles = serverData.documentFiles || [];
            const clientFiles = serverData.clientFiles || [];
            const pdnFiles = serverData?.pdnFiles || [];
            const uploadStatuses = serverData.bucketUploadedStatuses || {};

            setDocumentFileUrls(documentFiles);
            setClientFileUrls(clientFiles);
            setPdnFileUrls(pdnFiles);

            // Проверяем статусы загрузки документов
            const hasValidDocuments = uploadStatuses.DUL === 1 ||
                (uploadStatuses.DUL1 === 1 && uploadStatuses.DUL2 === 1);
            const hasValidClientPhoto = uploadStatuses.PHOTO === 1;
            const hasValidPdnPhoto = uploadStatuses.PDN === 1;

            setDocumentUploadSuccess(hasValidDocuments);
            setClientUploadSuccess(hasValidClientPhoto);
            setPdnUploadSuccess(hasValidPdnPhoto);

            // Если все файлы загружены, активируем кнопку Продолжить
            if (hasValidDocuments && hasValidClientPhoto && hasValidPdnPhoto) {
                setIsFormValid(true);
            } else {
                setIsFormValid(false);
            }
        }
    }, [serverData, setIsFormValid]); //setIsFormValid

    useEffect(() => {
        // Для режима sandbox проверяем наличие всех необходимых файлов
        if (sandbox) {
            const hasDocumentPhotos = documentFileUrls.some(url => url.includes('DUL'));
            const hasClientPhoto = clientFileUrls.some(url => url.includes('PHOTO'));
            const hasPdnPhoto = pdnFileUrls.some(url => url.includes('PDN'));

            // Если все файлы загружены, активируем кнопку Продолжить
            if (hasDocumentPhotos && hasClientPhoto && hasPdnPhoto) {
                setIsFormValid(true);
            } else {
                setIsFormValid(false);
            }
        }
    }, [sandbox, documentFileUrls, clientFileUrls, pdnFileUrls, setIsFormValid]);

    const documentPhotosInputRef = useRef<HTMLInputElement | null>(null);
    const clientPhotoInputRef = useRef<HTMLInputElement | null>(null);
    const pdnPhotoInputRef = useRef<HTMLInputElement | null>(null);

    const isDocumentDropzoneShouldBeDisabled = isLoading || isLoadingSandbox || (documentFiles.length === 1 && isSingleDocumentFile) || (documentFiles.length === 2 && !isSingleDocumentFile);
    const isClientDropzoneShouldBeDisabled = isLoading || isLoadingSandbox || clientFiles.length === 1;
    const isPdnDropzoneShouldBeDisabled = isLoading || isLoadingSandbox || pdnFiles.length === 1;

    const {
        error: documentError,
        onDrop: onDropDocument,
        isModalOpen,
        handleModalConfirm,
        handleModalClose
    } = usePhotoUpload({
        maxFiles: 2,
        isSingleFile: isSingleDocumentFile,
        setIsSingleFile: setIsSingleDocumentFile,
        currentFiles: documentFiles,
        setFiles: setDocumentFiles,
        type: 'document'
    });

    const {
        error: clientError,
        onDrop: onDropClient
    } = usePhotoUpload({
        maxFiles: 1,
        currentFiles: clientFiles,
        setFiles: setClientFiles,
        type: 'client'
    });

    const {
        error: pdnError,
        onDrop: onDropPdn
    } = usePhotoUpload({
        maxFiles: 1,
        currentFiles: pdnFiles,
        setFiles: setPdnFiles,
        type: 'pdn' as 'document' | 'client' | 'pdn'
    });

    // useEffect(() => {
    //     if ((isClientUploadSuccess || isDocumentUploadSuccess) && refetch && typeof refetch === 'function') {
    //         refetch();
    //     }
    // }, [isClientUploadSuccess, isDocumentUploadSuccess, refetch]);

    const isDocumentsValid = sandbox
        ? documentFiles.length > 0 // для sandbox только выбранные файлы
        : (documentFileUrls.length > 0 || documentFiles.length > 0); // для заявки либо загруженные, либо выбранные

    const isClientPhotosValid = sandbox
        ? clientFiles.length > 0 // для sandbox только выбранные файлы
        : (clientFileUrls.length > 0 || clientFiles.length > 0); // для заявки либо загруженные, либо выбранные

    const isPdnValid = sandbox
        ? pdnFiles.length > 0 // для sandbox только выбранные файлы
        : (pdnFileUrls.length > 0 || pdnFiles.length > 0); // для заявки либо загруженные, либо выбранные

    const hasNoErrors = !documentError && !clientError && !pdnError;

    useEffect(() => {
        const isValid = isDocumentsValid && isClientPhotosValid && isPdnValid && hasNoErrors;
        setIsFilesReady(isValid);
    }, [
        documentFileUrls,
        clientFileUrls,
        pdnFileUrls,
        documentFiles,
        clientFiles,
        pdnFiles,
        documentError,
        clientError,
        pdnError,
        isDocumentsValid,
        isClientPhotosValid,
        isPdnValid,
        hasNoErrors,
        isDocumentUploadSuccess,
        isClientUploadSuccess,
        isPdnUploadSuccess
    ]);

    const handleDeleteDocumentFile = async (index: number) => {
        if (index < documentFileUrls.length) {
            try {
                setDocumentFileUrls(prevUrls => prevUrls.filter((_, i) => i !== index));
            } catch (error) {
                console.error('Ошибка при удалении фото документов с сервера:', error);
            }
        } else {
            setDocumentFiles(prevFiles =>
                prevFiles.filter((_, i) => i !== index - documentFileUrls.length)
            );
        }

        // Сброс состояния isSingleDocumentFile, если файл был удален
        if (isSingleDocumentFile) {
            setIsSingleDocumentFile(false);
        }
    };

    const handleDeleteClientFile = async (index: number) => {
        if (index < clientFileUrls.length) {
            try {
                setClientFileUrls(prevUrls => prevUrls.filter((_, i) => i !== index));
            } catch (error) {
                console.error('Ошибка при удалении фото клиента с сервера:', error);
            }
        } else {
            setClientFiles(prevFiles =>
                prevFiles.filter((_, i) => i !== index - clientFileUrls.length)
            );
        }
    };

    const handleDeletePdnFile = async (index: number) => {
        if (index < pdnFileUrls.length) {
            try {
                setPdnFileUrls(prevUrls => prevUrls.filter((_, i) => i !== index));
            } catch (error) {
                console.error('Ошибка при удалении фото PDN с сервера:', error);
            }
        } else {
            setPdnFiles(prevFiles =>
                prevFiles.filter((_, i) => i !== index - pdnFileUrls.length)
            );
        }
    };

    const onSubmit = async (formData: any) => {
        if (!isFilesReady || !requestId) return;

        try {
            let response;

            if (sandbox) {
                const formDataToSend = new FormData();

                // Обработка документов
                if (isSingleDocumentFile) {
                    const [file] = documentFiles;
                    if (file) {
                        const newName = formatFileName(requestId, 'DUL', file);
                        const renamedFile = createFileWithNewName(file, newName);
                        formDataToSend.append('documentFiles', renamedFile);
                    }
                } else {
                    documentFiles.forEach((file, index) => {
                        const type: FileType = index === 0 ? 'DUL1' : 'DUL2';
                        const newName = formatFileName(requestId, type, file);
                        const renamedFile = createFileWithNewName(file, newName);
                        formDataToSend.append('documentFiles', renamedFile);
                    });
                }

                // Обработка фото клиента
                clientFiles.forEach(file => {
                    const newName = formatFileName(requestId, 'PHOTO', file);
                    const renamedFile = createFileWithNewName(file, newName);
                    formDataToSend.append('clientFiles', renamedFile);
                });

                // Обработка фото PDN
                pdnFiles.forEach(file => {
                    const newName = formatFileName(requestId, 'PDN', file);
                    const renamedFile = createFileWithNewName(file, newName);
                    formDataToSend.append('pdnFiles', renamedFile);
                });

                response = await uploadFilesSandbox({
                    formData: formDataToSend,
                    id: requestId
                }).unwrap();

                // В режиме sandbox у нас другая структура ответа
                if (response.documentFiles && response.documentFiles.length > 0) {
                    setDocumentUploadSuccess(true);
                    setDocumentFiles([]);
                    setDocumentFileUrls(response.documentFiles);
                }
                if (response.clientFiles && response.clientFiles.length > 0) {
                    setClientUploadSuccess(true);
                    setClientFiles([]);
                    setClientFileUrls(response.clientFiles);
                }
                if (response.pdnFiles && response.pdnFiles.length > 0) {
                    setPdnUploadSuccess(true);
                    setPdnFiles([]);
                    setPdnFileUrls(response.pdnFiles);
                }

                // Активируем кнопку Продолжить если все файлы загружены
                if (response.documentFiles && response.clientFiles && response.pdnFiles) {
                    // setIsFormValid(true);
                    setIsFilesReady(true);
                }

                // Обновляем данные с сервера после успешной загрузки
                if (refetch) {
                    await refetch();
                }

            } else {
                // Режим обычной заявки
                const files: { name: string; base64: string }[] = [];

                // Функция для конвертации File в base64
                const fileToBase64 = (file: File): Promise<string> => {
                    return new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () => {
                            const base64String = reader.result as string;
                            resolve(base64String.split(',')[1]);
                        };
                        reader.onerror = error => reject(error);
                    });
                };

                // Обработка документов
                if (isSingleDocumentFile) {
                    // Если загружен один файл, используем тип DUL
                    const [file] = documentFiles;
                    if (file) {
                        const newName = formatFileName(requestId, 'DUL', file);
                        const base64 = await fileToBase64(file);
                        files.push({ name: newName, base64 });
                    }
                } else {
                    // Если загружено два файла, используем DUL1 и DUL2
                    for (let i = 0; i < documentFiles.length; i++) {
                        const file = documentFiles[i];
                        const type = i === 0 ? 'DUL1' : 'DUL2';
                        const newName = formatFileName(requestId, type, file);
                        const base64 = await fileToBase64(file);
                        files.push({ name: newName, base64 });
                    }
                }

                // Обработка фото клиента
                for (const file of clientFiles) {
                    const newName = formatFileName(requestId, 'PHOTO', file);
                    const base64 = await fileToBase64(file);
                    files.push({ name: newName, base64 });
                }

                // Обработка фото PDN
                for (const file of pdnFiles) {
                    const newName = formatFileName(requestId, 'PDN', file);
                    const base64 = await fileToBase64(file);
                    files.push({ name: newName, base64 });
                }

                response = await uploadFiles({
                    files,
                    id: requestId
                }).unwrap();

                // Обработка ответа в обычном режиме
                if (response.status === 'success') {
                    if (response.documentFiles && response.documentFiles.length > 0) {
                        setDocumentUploadSuccess(true);
                        setDocumentFiles([]);
                        setDocumentFileUrls(response.documentFiles);
                    }
                    if (response.clientFiles && response.clientFiles.length > 0) {
                        setClientUploadSuccess(true);
                        setClientFiles([]);
                        setClientFileUrls(response.clientFiles);
                    }
                    if (response.pdnFiles && response.pdnFiles.length > 0) {
                        setPdnUploadSuccess(true);
                        setPdnFiles([]);
                        setPdnFileUrls(response.pdnFiles);
                    }
                }
            }

            // Закомментируем автоматический переход на следующий шаг
            /*
            if (onFormSubmit) {
                onFormSubmit(formData);
            }
            */

            refetch();
        } catch (error: any) {
            console.error('Ошибка при загрузке файлов:', error);

            // Проверяем наличие ошибки о превышении размера файла
            if (error?.status === 413 ||
                (error?.data?.message === 'request entity too large') ||
                error?.message?.includes('request entity too large')) {
                setUploadError('Размер загружаемых файлов слишком большой. Пожалуйста, уменьшите размер файлов (максимум 10MB) или загрузите их по отдельности.');
                setIsModalErrorOpen(true);
                return;
            }

            // Общая ошибка загрузки
            setUploadError('Произошла ошибка при загрузке файлов. Пожалуйста, попробуйте снова.');
            setIsModalErrorOpen(true);
        }
    };

    //тест отображения ошибки
    // const onSubmit = async (formData: any) => {
    //     setUploadError("Ошибка при загрузке файлов. Проверьте размер и формат.");
    //     setIsModalErrorOpen(true);
    // };

    // Проверяем, были ли успешно отправлены файлы
    const isFilesSuccessfullyUploaded = sandbox
        ? documentFileUrls.some(url => url.includes('DUL')) &&
        clientFileUrls.some(url => url.includes('PHOTO')) &&
        pdnFileUrls.some(url => url.includes('PDN'))
        : (isDocumentUploadSuccess && isClientUploadSuccess && isPdnUploadSuccess);

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                <div className={styles.flexRow}>
                    <CardWrapper title="Фото документов">

                        {(sandbox
                            ? documentFileUrls.some(url => url.includes('DUL'))
                            : (isDocumentUploadSuccess ||
                                serverData?.bucketUploadedStatuses?.DUL === 1 ||
                                (serverData?.bucketUploadedStatuses?.DUL1 === 1 && serverData?.bucketUploadedStatuses?.DUL2 === 1)
                            )
                        ) ? (
                            <div className={styles.successBlock}>
                                <Icon icon="mdi:check-circle" color="#4CAF50" width="24px" />
                                <span>Фото успешно загружены</span>
                            </div>
                        ) : (
                            <>
                                <div className={styles.photoSubtitleWrapper}>
                                    <div>Загрузите фото или сканы паспорта:</div>
                                    <ul className={styles.ulList} >
                                        <li>Разворот 2-3 страницы</li>
                                        <li>Страница актуальной прописки</li>
                                    </ul>
                                </div>
                                <FileDropzone
                                    onDrop={onDropDocument}
                                    inputRef={documentPhotosInputRef}
                                    error={documentError as string}
                                    isDisabled={isDocumentDropzoneShouldBeDisabled}
                                    type="document"
                                    maxFiles={2}
                                />
                                <FilePreview
                                    files={!sandbox ? [...documentFiles] : [...documentFileUrls, ...documentFiles]}
                                    onDelete={handleDeleteDocumentFile}
                                    type="document"
                                />
                            </>
                        )}
                    </CardWrapper>

                    <CardWrapper title="Фото с клиентом">

                        {(sandbox ? clientFileUrls.some(url => url.includes('PHOTO')) :
                            (isClientUploadSuccess || serverData?.bucketUploadedStatuses?.PHOTO === 1)) ? (
                            <div className={styles.successBlock}>
                                <Icon icon="mdi:check-circle" color="#4CAF50" width="24px" />
                                <span>Фото успешно загружено</span>
                            </div>
                        ) : (
                            <>
                                <div className={styles.photoSubtitleWrapper}>
                                    <div>Загрузите фото клиента:</div>
                                    <ul className={styles.ulList} >
                                        <li>Лицо клиента должно быть открыто, без посторонних предметов</li>
                                        <li>Выражение лица — нейтральное</li>
                                    </ul>
                                </div>
                                <FileDropzone
                                    onDrop={onDropClient}
                                    inputRef={clientPhotoInputRef}
                                    error={clientError as string}
                                    isDisabled={isClientDropzoneShouldBeDisabled}
                                    type="client"
                                    maxFiles={1}
                                />
                                <FilePreview
                                    files={!sandbox ? [...clientFiles] : [...clientFileUrls, ...clientFiles]}
                                    onDelete={handleDeleteClientFile}
                                    type="client"
                                />
                            </>
                        )}

                    </CardWrapper>


                </div>
                <CardWrapper title="Согласие на обработку персональных данных">
                    {(sandbox ? pdnFileUrls.some(url => url.includes('PDN')) :
                        (isPdnUploadSuccess || serverData?.bucketUploadedStatuses?.PDN === 1)) ? (
                        <div className={styles.successBlock}>
                            <Icon icon="mdi:check-circle" color="#4CAF50" width="24px" />
                            <span>Файл успешно загружен</span>
                        </div>
                    ) : (
                        <>
                            <div className={styles.photoSubtitleWrapper}>
                                <div>Загрузите подписанное согласие на обработку персональных данных:</div>
                                <ul className={styles.ulList}>
                                    <li>Документ должен быть подписан клиентом</li>
                                    <li>Все страницы документов должны быть отсканированы</li>
                                </ul>
                            </div>
                            <FileDropzone
                                onDrop={onDropPdn}
                                inputRef={pdnPhotoInputRef}
                                error={pdnError as string}
                                isDisabled={isPdnDropzoneShouldBeDisabled}
                                type={'pdn' as 'document' | 'client' | 'pdn'}
                                maxFiles={1}
                            />
                            <FilePreview
                                files={!sandbox ? [...pdnFiles] : [...pdnFileUrls, ...pdnFiles]}
                                onDelete={handleDeletePdnFile}
                                type={'pdn' as 'document' | 'client' | 'pdn'}
                            />
                        </>
                    )}
                </CardWrapper>
                <ModalWithChildren
                    isOpen={isModalOpen}
                    onClose={handleModalClose}
                // title="Загрузка файла"
                >
                    <div className={styles.modalContent}>
                        <img src={YellowClockIcon} alt="Yellow Clock Icon" width={"28px"} />
                        <p className={styles.modalText}>Вы загружаете один файл. Это общий файл, содержащий оба разворота паспорта?</p>
                        <div className={styles.modalButtons}>
                            <ButtonUI label="Нет" variant="main2" onClick={handleModalClose} width='80px' />
                            <ButtonUI label="Да" variant="main" onClick={handleModalConfirm} width='80px' />
                        </div>
                    </div>
                </ModalWithChildren>
                <ModalWithChildren
                    isOpen={isModalErrorOpen}
                    onClose={handleModalErrorClose}
                    title="Ошибка загрузки"
                >
                    <div className={styles.errorModalContent}>
                        <Icon icon="mdi:alert-circle" color="#ff4d4f" width="24" height="24" />
                        <p>{uploadError}</p>
                    </div>
                </ModalWithChildren>
                {(sandbox ? (!isFilesSuccessfullyUploaded && (!!documentFiles.length || !!clientFiles.length || !!pdnFiles.length)) :
                    (!isFilesSuccessfullyUploaded && ((isDocumentUploadSuccess || isClientUploadSuccess || isPdnUploadSuccess) || (clientFiles.length > 0 && documentFiles.length > 0 && pdnFiles.length > 0)))) && (
                        <div className={styles.submitButtonContainer}>
                            <ButtonUI
                                variant="secondary"
                                label={(isLoading || isLoadingSandbox) ? 'Отправка...' : 'Отправить'}
                                type="submit"
                                fullWidth
                                width='275px'
                                disabled={!isFilesReady || isLoading || isLoadingSandbox}
                            />
                        </div>
                    )}
            </form>
        </FormProvider>
    );
};
