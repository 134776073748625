import { RootState } from '../../app/store';

export const selectCurrentStep = (state: RootState) => state.request.currentStep;
export const selectStatus = (state: RootState) => state.request.status;
export const selectRequestId = (state: RootState) => state.request.requestId;
export const selectSelectedBank = (state: RootState) => state.request.data.selectedBank;
export const selectSelectedProduct = (state: RootState) => state.request.data.selectedProduct;
export const selectPersonalData = (state: RootState) => state.request.data.personalData;
export const selectAdditionalData = (state: RootState) => state.request.data.additionalData;
export const selectUserId = (state: RootState) => state.login?.user?._id;
