import React, { useState, useEffect } from 'react';
import { CardWrapper } from 'components/CardWrapper/CardWrapper';
import { setRequestedAmount } from 'features/request/requestSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useGetCardRequestByIdQuery, useUpdateRequestStepMutation } from 'services/api';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import { useSliderDebounce } from 'shared/hooks/useSliderDebounce';

import styles from './CreditSlider.module.scss';

interface CreditSliderProps {
    requestId: string | null;
}

const marks = [
    {
        value: 10000,
        label: '10 тыс.',
    },
    {
        value: 120000,
        label: '120 тыс.',
    },
    {
        value: 280000,
        label: '280 тыс.',
    },
    {
        value: 510000,
        label: '510 тыс.',
    },
];

function valuetext(value: number) {
    return `${value.toLocaleString('ru-RU')} ₽`;
}

const PrettoSlider = styled(Slider)({
    color: '#71BE81',
    height: 8,
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-thumb': {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '5px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&::before': {
            display: 'none',
        },
    },
    '& .MuiSlider-rail': {
        backgroundColor: '#E5E5E5',
        opacity: 0.5,
    },
    '& .MuiSlider-mark': {
        backgroundColor: '#E2E2ED',
        height: 8,
        width: 8,
        marginTop: 0,
        borderRadius: '50%',
    },
});

export const CreditSlider: React.FC<CreditSliderProps> = ({ requestId }) => {
    const dispatch = useDispatch();
    const [value, setValue] = useState<number | null>(null);
    const [isInitialized, setIsInitialized] = useState(false);
    const [wasChanged, setWasChanged] = useState(false);
    const [updateRequestStep] = useUpdateRequestStepMutation();

    const { data: requestData, refetch } = useGetCardRequestByIdQuery(requestId, { skip: !requestId });

    // Используем debounce для значения слайдера
    const debouncedValue = useSliderDebounce(value ?? 0, 500);

    // При монтировании компонента делаем refetch
    useEffect(() => {
        if (requestId) {
            refetch();
        }
    }, [requestId, refetch]);

    // Инициализация значения из данных с сервера
    useEffect(() => {
        if (!isInitialized && requestData?.requestedAmount !== undefined) {
            console.log('Setting initial value from server:', requestData.requestedAmount);
            setValue(requestData.requestedAmount);
            setIsInitialized(true);
        }
    }, [requestData, isInitialized]);

    // Эффект для обновления значения на сервере при изменении debouncedValue
    useEffect(() => {
        // Отправляем на сервер только если слайдер был изменен пользователем
        if (!isInitialized || !wasChanged) return;

        // Сохраняем изменения на сервере только если значение изменилось
        if (requestId && debouncedValue !== requestData?.requestedAmount) {
            console.log('Sending to server:', debouncedValue);
            updateRequestStep({
                id: requestId,
                step: 2,
                data: { requestedAmount: debouncedValue }
            }).then(() => {
                // После успешного обновления делаем refetch
                refetch();
            });
        }
    }, [debouncedValue, requestId, isInitialized, requestData?.requestedAmount, wasChanged, refetch]);

    const handleSliderChange = (_event: Event, newValue: number | number[]) => {
        const sliderValue = Array.isArray(newValue) ? newValue[0] : newValue;
        setValue(sliderValue);
        setWasChanged(true);
    };

    // Не рендерим слайдер пока не получим начальное значение
    if (!isInitialized || value === null) {
        console.log('Waiting for initialization...');
        return null;
    }

    return (
        <CardWrapper title="Настройте Вашу карту" minWidth="512">
            <div className={styles.requestedAmount}>
                <div>Запрашиваемая сумма кредита:</div>
                <div className={styles.value}>{valuetext(value)}</div>
            </div>
            <Box sx={{ width: '100%', padding: '20px 10px' }}>
                <PrettoSlider
                    value={value}
                    onChange={handleSliderChange}
                    aria-labelledby="credit-slider"
                    getAriaValueText={valuetext}
                    valueLabelDisplay="off"
                    step={1000}
                    marks={marks}
                    min={10000}
                    max={600000}
                />
            </Box>
        </CardWrapper>
    );
};
