import React, { useEffect, useState } from 'react';
import ButtonUI from 'components/ui/ButtonUI/ButtonUI';
import { useCancelCreditCardMutation, useGetBidStatusQuery, useUpdateRequestStatusMutation, useGetBidsByUserIdQuery } from 'services/api';
import { useMediaQuery } from 'shared/hooks/UseMediaQuery';
import { PulseLoader } from 'react-spinners';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ArrowLeft from 'assets/icons/ArrowLeft';
import ArrowRightWhite from 'assets/icons/ArrowRightWhite';

import styles from './ButtonSteps.module.scss';

const POLLING_INTERVAL = 3000; // 3 секунды

interface BottonStepsProps {
    step: number;
    onNext: () => void;
    onPrev: () => void;
    disableNext?: boolean;
    requestId?: string;
}

const BottonSteps: React.FC<BottonStepsProps> = ({ step, onNext, onPrev, disableNext, requestId }) => {
    const navigate = useNavigate();
    const userId = useSelector((state: any) => state.login?.user?._id);
    const [cancelCreditCard] = useCancelCreditCardMutation();
    const [updateRequestStatus] = useUpdateRequestStatusMutation();
    const isMobile = useMediaQuery(1250);
    const [isPolling, setIsPolling] = useState(false);
    const [isCancelLoading, setIsCancelLoading] = useState(false);
    const [cancelMessage, setCancelMessage] = useState<string>('');
    const [hasError, setHasError] = useState(false);

    // Запрос для обновления списка заявок
    const { refetch: refetchBids } = useGetBidsByUserIdQuery(userId);

    // Запрос статуса с поллингом
    const { data: statusData } = useGetBidStatusQuery(
        requestId && isPolling ? { id: requestId, type: 'cancel' } : skipToken,
        {
            pollingInterval: isPolling ? POLLING_INTERVAL : 0,
            refetchOnMountOrArgChange: true
        }
    );

    const handleCancel = async () => {
        if (!requestId || hasError) return;

        setIsCancelLoading(true);
        setIsPolling(true);
        setCancelMessage('');
        setHasError(false);

        try {
            await cancelCreditCard({ requestId });
        } catch (error) {
            console.error('Error canceling credit card:', error);
            setIsCancelLoading(false);
            setIsPolling(false);
            setHasError(true);
            setCancelMessage('Ошибка при отмене заявки');
        }
    };

    // Обработка статуса отмены
    useEffect(() => {
        if (!statusData || !requestId) return;

        const { partnerResponseCode, partnerResponseMessage } = statusData.message || {};

        // Устанавливаем сообщение об ошибке, если оно есть
        if (partnerResponseMessage) {
            setCancelMessage(partnerResponseMessage);
        }

        // Останавливаем загрузку и пуллинг для всех кодов кроме -1
        if (partnerResponseCode !== -1) {
            setIsCancelLoading(false);
            setIsPolling(false);

            // Устанавливаем флаг ошибки, если код не 0
            if (partnerResponseCode !== 0) {
                setHasError(true);
            }

            if (partnerResponseCode === 0 || partnerResponseCode === 3) {
                // Успешная отмена
                setHasError(false);
                // Успешная отмена
                updateRequestStatus({ id: requestId, status: 'canceled' })
                    .then(() => {
                        refetchBids();
                        setTimeout(() => {
                            navigate('/main');
                        }, 1000);
                    })
                    .catch(error => {
                        console.error('Error updating request status:', error);
                        setCancelMessage('Ошибка при обновлении статуса заявки');
                        setHasError(true);
                    });
            }
        }
    }, [statusData, requestId, updateRequestStatus, refetchBids, navigate]);

    const buttonBlockClass = step === 1 ? styles.buttonBlockStep1
        : (step === 9 || step === 10)
            ? `${styles.buttonBlock} ${styles.oneButtonCenter}`
            : styles.buttonBlock;

    const cancelMessageFiltered = cancelMessage === 'Успех.' ? 'Заявка отменена' : cancelMessage;
    return (
        <div className={buttonBlockClass}>
            <div className={styles.twoButtonBlock}>
                {step > 0 && step !== 9 && step !== 10 && (
                    <ButtonUI
                        width={isMobile ? 'auto' : '275px'}
                        label={isMobile ? '' : "Вернуться"}
                        variant="main2"
                        onClick={onPrev}
                        iconLeft={<ArrowLeft />}
                        disabled={step > 6}
                    />
                )}
                <ButtonUI
                    width={isMobile ? '100%' : '275px'}
                    label={isCancelLoading ? '' : "Продолжить"}
                    variant="secondary"
                    onClick={onNext}
                    iconRight={!isCancelLoading && <ArrowRightWhite />}
                    disabled={disableNext || isCancelLoading}
                >
                    {isCancelLoading && <PulseLoader color="#fff" size={8} />}
                </ButtonUI>
            </div>
            {requestId && (
                <div className={styles.cancelBlock} style={isMobile ? { width: '100%' } : { marginLeft: 'auto' }}>
                    <ButtonUI
                        width={isMobile ? '100%' : '275px'}
                        label={isCancelLoading ? '' : cancelMessageFiltered || "Отменить заявку"}
                        variant="main2"
                        onClick={handleCancel}
                        disabled={isCancelLoading}
                    >
                        {isCancelLoading && <PulseLoader color="#000" size={8} />}
                    </ButtonUI>
                </div>
            )}
        </div>
    );
};

export default BottonSteps;
