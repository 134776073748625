import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { PhotoForm } from 'components/PhotoForm/PhotoForm';
import {
    useGetCardRequestByIdQuery,
    useUpdateRequestStepMutation,
    useRequestSmsCodeMutation
} from 'services/api';

interface Step4Props {
    setIsFormValid: (isValid: boolean) => void;
    onFormSubmit: (data: any) => void;
    requestId: string | null;
    setRequestId: (requestId: any) => void;
}

const Step4: React.FC<Step4Props> = ({ setIsFormValid, onFormSubmit, requestId, setRequestId }) => {
    const { data: cardRequestData, refetch } = useGetCardRequestByIdQuery(requestId);
    const [updateRequestStep] = useUpdateRequestStepMutation();
    const [requestSmsCode] = useRequestSmsCodeMutation();

    const [isRequestDataReady, setIsRequestDataReady] = useState(false);

    const methods = useForm({
        mode: 'onChange',
        defaultValues: cardRequestData || {},
    });

    const { reset } = methods;

    // useEffect(() => {
    //     if (cardRequestData) {
    //         reset(cardRequestData);
    //     }
    // }, [cardRequestData, reset]);

    useEffect(() => {
        if (cardRequestData) {
            reset(cardRequestData);

            // Проверяем статусы загрузки файлов
            const uploadStatuses = cardRequestData.bucketUploadedStatuses || {};
            const hasValidDocuments = uploadStatuses.DUL === 1 ||
                (uploadStatuses.DUL1 === 1 && uploadStatuses.DUL2 === 1);
            const hasValidClientPhoto = uploadStatuses.PHOTO === 1;
            const hasValidPdnFile = uploadStatuses.PDN === 1;

            // Если все файлы загружены, активируем форму
            if (hasValidDocuments && hasValidClientPhoto && hasValidPdnFile) {
                setIsRequestDataReady(true);
                setIsFormValid(true); // Устанавливаем форму как валидную
            } else {
                setIsRequestDataReady(false);
                setIsFormValid(false); // Устанавливаем форму как невалидную
            }
        }
    }, [cardRequestData, reset, setIsFormValid]);

    useEffect(() => {
        refetch();
    }, [refetch]);

    const handleFormValidityChange = (isValid: boolean) => {
        // Устанавливаем isFormValid всегда, когда меняется валидность формы
        setIsFormValid(isValid);
    };

    const [isSendingSms, setIsSendingSms] = useState(false);

    const onSubmit = async (data: any) => {
        if (!requestId) return;

        try {
            // Обновляем данные шага
            const updatedData = await updateRequestStep({
                id: requestId,
                step: 4,
                data: data
            }).unwrap();

            // Если телефон уже верифицирован, пропускаем отправку SMS
            if (updatedData.data?.phoneVerified) {
                console.log(" ~ Step4 ~ Phone is already verified in DB, skipping SMS");
                onFormSubmit(data);
                return;
            }

            // Отправляем SMS только если телефон не верифицирован
            const result = await requestSmsCode(requestId).unwrap();
            if (result.smsResult?.includes('ERROR')) {
                console.error('Error sending SMS:', result.smsResult);
                return;
            }

            onFormSubmit(data);
        } catch (error) {
            console.error('Error in Step4.onSubmit:', error);
        }
    };

    return (
        <PhotoForm
            sandbox={cardRequestData?.sandbox}
            setIsFormValid={handleFormValidityChange}
            onFormSubmit={onSubmit}
            serverData={{
                documentFiles: cardRequestData?.documentPhotos || [],
                clientFiles: cardRequestData?.clientPhoto ? [cardRequestData.clientPhoto] : [],
                bucketUploadedStatuses: cardRequestData?.bucketUploadedStatuses || {},
                pdnFiles: cardRequestData?.pdnPhoto || [],
            }}
            requestId={requestId || undefined}
            refetch={refetch}
        />
    );
};

export default Step4;
